import { css } from '@emotion/react';
import { useEffect } from 'react';

import { headerLinks } from '@/components/Header/Header';
import Link from '@/components/Link/Link';
import { HEADER_HEIGHT } from '@/config/constants';
import { ColorPalette, fontWeight, media, spacing } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';

const styles = {
  navigationWrapper: css`
    background-color: ${ColorPalette.white};
    width: 100%;
    height: 100%;
    top: ${HEADER_HEIGHT}px;
    position: absolute;
    z-index: 1;
    align-items: center;
    justify-content: center;

    ${media.desktop} {
      display: none;
    }

    ${media.smallTablet} {
      display: flex;
    }
  `,
  link: css`
    color: ${ColorPalette.black};
    margin-bottom: ${spacing['12']};
    font-weight: ${fontWeight.light};
  `,
  linkWrapper: css`
    display: grid;
    text-align: center;
    margin-bottom: ${HEADER_HEIGHT}px;
  `,
};

type MobileNavigationProps = {
  onLinkPress: () => void;
};

const MobileNavigation = ({ onLinkPress }: MobileNavigationProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <nav css={styles.navigationWrapper}>
      <div css={styles.linkWrapper}>
        {headerLinks.map((headerLink, index) => (
          <Link href={headerLink.link} key={index} css={styles.link} onClick={onLinkPress}>
            {t(headerLink.label).toUpperCase()}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default MobileNavigation;
