import { css } from '@emotion/react';

export const mobile = 768;
export const tablet = 992;
export const desktop = 1280;
export const bigDesktop = 1366;

export const media = {
  mobile: `@media(max-width: ${mobile}px)`,
  smallTablet: `@media(max-width: ${tablet}px)`,
  tablet: `@media(max-width: ${desktop - 1}px)`,
  desktop: `@media(max-width: ${bigDesktop - 1}px)`,
} as const;

export const fontWeight = {
  light: '300',
  semiBold: '600',
  bold: '700',
} as const;

export const ColorPalette = {
  primary: '#130A3B',
  secondary: '#F26C24',
  darkGray: '#1A1A1A',
  mediumGray: '#7F7F7F',
  smoke: '#CACACA',
  lightGray: '#DFDFDF',
  white: '#FFFFFF',
  gray: '#666666',
  green: '#009900',
  red: '#FF0000',
  black: '#000000',
  intenseOrange: '#CE4D08',
  black_05: 'rgba(0, 0, 0, 0.05)',
  black_30: 'rgba(0, 0, 0, 0.3)',
  black_40: 'rgba(0, 0, 0, 0.4)',
  black_60: 'rgba(0, 0, 0, 0.6)',
  extraLightOrange: '#FCE9E5',
  lightBlue: '#89849d',
  lightLavender: '#F1F1F1',
} as const;

export const spacing = {
  0: '0px',
  2: '2px',
  5: '5px',
  6: '6px',
  8: '8px',
  10: '10px',
  12: '12px',
  16: '16px',
  24: '24px',
  20: '20px',
  28: '28px',
  32: '32px',
  40: '40px',
  48: '48px',
  52: '52px',
  64: '64px',
  100: '100px',
};

export const styleUtils = {
  buttonReset: css`
    border: none;
    background-color: unset;
    padding: ${spacing['0']};
    cursor: pointer;
  `,
  alignCenter: css`
    justify-content: center;
    align-items: center;
  `,
  linkReset: css`
    color: ${ColorPalette.black};
    text-decoration: none;
    padding: 0;
  `,
  displayFlex: css`
    display: flex;
  `,
  mb10: css`
    margin-bottom: ${spacing['10']};
  `,
  mt10: css`
    margin-top: ${spacing['10']};
  `,
  mr10: css`
    margin-right: ${spacing['10']};
  `,
};

export const borderRadius = {
  4: '4px',
} as const;

export const boxStyle = {
  boxShadow: `0px 0px 5px -3px ${ColorPalette.darkGray}`,
  border: `1px solid ${ColorPalette.lightGray}`,
  borderRadius: '5px',
} as const;

export const fontSize = {
  10: '0.6rem',
  12: '0.75rem',
  14: '0.875rem',
  16: '1rem',
  20: '1.25rem',
  24: '1.5rem',
  32: '2rem',
} as const;
