const config = {
  NODE_ENV: process.env.NEXT_PUBLIC_ENV,
  API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL || '',
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'dev',
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || 'MISSING_ID',
  API_IMAGES_URL: process.env.NEXT_PUBLIC_API_IMAGES_URL || '',
  SITE_URL: process.env.NEXT_PUBLIC_SITE_URL || '',
  GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '',
};

export default config;
