import { css } from '@emotion/react';
import React from 'react';

import { bigDesktop, media, spacing } from '@/config/style';

const styles = {
  container: css`
    width: 100%;
    margin: 0 auto;
    max-width: ${bigDesktop}px;
    padding: 0 ${spacing['20']};

    ${media.mobile} {
      padding: 0 ${spacing['10']};
    }
  `,
};

interface ContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  return <div css={styles.container}>{children}</div>;
};

export default Container;
