import { css } from '@emotion/react';

import { ColorPalette, media, spacing } from '@/config/style';

export interface HamburgerProps {
  isOpen: boolean;
  onClick: () => void;
}

const styles = {
  button: css`
    background: none;
    border: none;
    height: 100%;
    cursor: pointer;
    display: none;

    ${media.smallTablet} {
      display: block;
    }
  `,

  hamburgerLine: css`
    border-radius: 5px;
    width: 30px;
    height: 3px;
    background-color: ${ColorPalette.black};
    display: block;
    margin: ${spacing['6']} 0;
    transition: all 0.3s ease-in-out;
  `,
  line1: (isOpen: boolean) => css`
    transform: ${isOpen && 'translateY(8px) rotate(40deg)'};
  `,

  line2: (isOpen: boolean) => css`
    width: ${isOpen && 0};
  `,

  line3: (isOpen: boolean) => css`
    transform: ${isOpen && 'translateY(-10px) rotate(-40deg)'};
  `,
};

const Hamburger = ({ isOpen, onClick }: HamburgerProps) => {
  return (
    <button css={styles.button} onClick={onClick} type="button">
      <span css={[styles.hamburgerLine, styles.line1(isOpen)]} />
      <span css={[styles.hamburgerLine, styles.line2(isOpen)]} />
      <span css={[styles.hamburgerLine, styles.line3(isOpen)]} />
    </button>
  );
};

export default Hamburger;
