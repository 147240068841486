export const getDirectionsService = (options: {
  destination: {
    lat: number;
    lng: number;
  };
  origin: {
    lat: number;
    lng: number;
  };
  waypoints: {
    location: {
      lat: number;
      lng: number;
    };
  }[];
  travelMode: google.maps.TravelMode;
}) => {
  const directionsService = new google.maps.DirectionsService();

  return directionsService.route(options);
};
