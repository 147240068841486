import NextLink from 'next/link';
import React from 'react';

import { styleUtils } from '@/config/style';

type LinkProps = {
  href: string;
  children?: React.ReactNode;
  locale?: string | false;
  prefetch?: boolean;
} & React.HTMLProps<HTMLAnchorElement> &
  React.RefAttributes<HTMLAnchorElement>;

const Link = ({ href, children, locale, prefetch, ...rest }: LinkProps) => {
  return (
    <NextLink {...rest} href={href} passHref locale={locale} prefetch={prefetch} css={styleUtils.linkReset}>
      {children}
    </NextLink>
  );
};

export default Link;
