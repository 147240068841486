import { css, Global } from '@emotion/react';
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import NextHead from 'next/head';
import { DefaultSeo } from 'next-seo';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import '@/utils/i18n';
import TagManager from 'react-gtm-module';

import MainLayout from '@/components/MainLayout/MainLayout';
import { ModalProvider } from '@/components/modal/Modal';
import config from '@/config/config';
import seoConfig from '@/config/seo.json';
import { ColorPalette, fontWeight } from '@/config/style';
import { prepareSeoConfig } from '@/utils/utils';

type NextPageWithLayout = NextPage<{ dehydratedState: DehydratedState }> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type MyAppProps = {
  Component: NextPageWithLayout;
} & AppProps<{ dehydratedState: DehydratedState }>;

const globalStyles = css`
  * {
    box-sizing: border-box;
    font-family: raleway, sans-serif;
  }
  html,
  body {
    height: 100%;
    min-height: 100%;
    margin: unset;
    background-color: ${ColorPalette.lightLavender};
    font-variant: lining-nums;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    margin: 0;
  }

  @font-face {
    font-family: 'Lovelo';
    src: url('/fonts/Lovelo-LineLight.otf');
    font-weight: ${fontWeight.light};
    font-display: block;
  }
`;

const Head = () => {
  return (
    <>
      <DefaultSeo {...prepareSeoConfig(seoConfig)} />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <style>
          {`
            #__next { height: 100% }
          `}
        </style>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preload" href="/fonts/Lovelo-LineLight.otf" as="font" type="font/otf" />
        <link
          href={`https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500&display=swap`}
          rel="stylesheet"
        />
      </NextHead>
    </>
  );
};

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  useEffect(() => {
    if (config.GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({ gtmId: config.GOOGLE_TAG_MANAGER_ID });
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
          mutations: {
            retry: false,
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Global styles={globalStyles} />
      <Hydrate state={pageProps.dehydratedState}>
        <ModalProvider>
          <Head />
          <MainLayout>{getLayout(<Component {...pageProps} />)}</MainLayout>
        </ModalProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default MyApp;
