import { css } from '@emotion/react';

import Container from '@/components/Container/Container';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';

const styles = {
  wrapper: css`
    margin: auto;
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
  `,
  main: css`
    min-height: 100%;
    height: max-content;
    display: flex;
  `,
};

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <div css={styles.wrapper}>
      <Header />
      <main css={styles.main}>
        <Container>{children}</Container>
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
