import * as Sentry from '@sentry/browser';

import config from '@/config/config';

export const logError = (message: string) => {
  if (config.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info(message);
  } else if (config.NODE_ENV === 'production') {
    Sentry.captureMessage(message);
  }
};
