import { DefaultSeoProps } from 'next-seo';

import config from '@/config/config';
import { MAX_WAYPOINT_LIMIT } from '@/config/constants';
import { Site } from '@/services/tour';

import { getDirectionsService } from './googleDirectionsUtils';
import { TravelType } from './types';

export const getHoursAndMinutes = (time: number) => {
  const minutes = time % 60;
  const hours = (time - minutes) / 60;
  return { hours, minutes };
};

export const getDistanceInKm = (distanceInMetres: number) => {
  return (distanceInMetres / 1000).toFixed(2);
};

export const noop = () => {};

export const range = (amount: number) => Array.from(Array(amount).keys());

export const chunkArrayInGroups = <T>(arr: T[], size: number) => {
  const groups: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    groups.push(arr.slice(i, i + size));
  }

  return groups;
};

export const convertCentsToEuros = (amount: number) => {
  return `€ ${amount / 100}`;
};

export function assertIsDefined<T>(val: T, errorMsg: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(errorMsg);
  }
}

export const removeLeadingSlashes = (path: string): string => {
  if (path.charAt(0) !== '/') {
    return path;
  }
  return removeLeadingSlashes(path.slice(1));
};

export const prepareSeoConfig = (seoConfig: DefaultSeoProps) => {
  return {
    ...seoConfig,
    openGraph: {
      ...seoConfig.openGraph,
      images: seoConfig.openGraph?.images?.map((image) => ({
        ...image,
        url: `${config.SITE_URL}${removeLeadingSlashes(image.url)}`,
      })),
    },
  };
};

export type TourSitesDirectionsProps = {
  sites: Site[];
  travelType: TravelType;
  isLoaded: boolean;
};

type DirectionsPayload = {
  routes: { legs: google.maps.DirectionsLeg[] }[];
  request: { travelMode: google.maps.TravelMode };
};

export const preparePathChunks = (sites: Site[]) => {
  const paths = sites.map((site) => ({ lat: site.location.latitude, lng: site.location.longitude }));
  const pathChunks = chunkArrayInGroups(paths, MAX_WAYPOINT_LIMIT);

  return pathChunks;
};

export const getGooglePathLegs = async (
  pathChunks: {
    lat: number;
    lng: number;
  }[][],
  travelType: TravelType,
) => {
  const travelTypeMap: { [key in TravelType]: google.maps.TravelMode } = {
    bike: google.maps.TravelMode.BICYCLING,
    car: google.maps.TravelMode.DRIVING,
    walk: google.maps.TravelMode.WALKING,
    bus: google.maps.TravelMode.TRANSIT,
  };

  const result = await Promise.all(
    pathChunks.map(async (chunk) => {
      const options = {
        destination: chunk[chunk.length - 1],
        origin: chunk[0],
        waypoints: chunk.map((location) => ({ location })),
        travelMode: travelTypeMap[travelType],
      };
      // Library types are incorrect, but note that I am only passing the required ones for this functionality
      const result = (await getDirectionsService(options)) as unknown as DirectionsPayload;
      // Routes only have 1 item
      const routeLegs = result.routes[0].legs;

      return { legs: routeLegs, travelMode: result.request.travelMode };
    }),
  );
  return result;
};

export const isDefined = <T>(value: T): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

export const getTourSitesRoute = async ({ sites, travelType, isLoaded }: TourSitesDirectionsProps) => {
  if (!isLoaded) {
    return null;
  }
  const pathChunks = preparePathChunks(sites);
  const googlePathLegs = await getGooglePathLegs(pathChunks, travelType);

  return {
    legs: googlePathLegs.flatMap((item) => item.legs),
    request: { travelMode: googlePathLegs[0].travelMode },
  };
};

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export function isString(value: unknown): value is string {
  return typeof value === 'string' || value instanceof String;
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean' || value instanceof Boolean;
}
