import { TourFilterFormValues } from '@/components/form/TourFilterForm';
import { AttractionsFilterParams } from '@/services/attractions';

export const HEADER_HEIGHT = 80;

export const CITY_CARD_SIZE = 300;
export const CITY_PAGE_CARD_WIDTH = 340;
export const CITY_PAGE_CARD_HEIGHT = 360;
export const MAX_WAYPOINT_LIMIT = 25;

export const ATTRACTIONS_FETCH_LIMIT = 9;

export const ATTRACTIONS_FILTERS_DEFAULT_VALUES: AttractionsFilterParams = { is_picturesque: null, tags: [] };

export const TOUR_FILTERS_DEFAULT_VALUES: TourFilterFormValues = {
  max_duration: 240,
  tags: [],
  travel_type: [],
  language_code: '',
};

export const ANCHOR_TAG_REGEX = /<\/?a[^>]*>/g;
