export default {
  msg_card_button_title: 'Tours Info',
  msg_card_alt_text: 'A tour card of',
  msg_card_title: 'Where to?',
  msg_searchbar_tooltip_text: 'You need to enter at least 3 letters',
  msg_searchbar_aria_label: 'Search through site content',
  msg_searchbar_placeholder: 'Search',
  msg_city_tour_not_found: "Sorry, can't find this particular place :(",
  msg_load_more: 'Load more',
  msg_text_clear: 'Clear',
  msg_text_filter_tours: 'Filters',
  msg_no_tours_available: 'There are no tours available at the moment.',
  msg_tour_duration: 'Duration: ',
  msg_tour_distance: 'Distance: ',
  msg_text_filter_tours_interest_label: 'Interests',
  msg_text_filter_tours_language_code_label: 'Language',
  msg_text_filter_tours_travel_type_label: 'Travel mode',
  msg_tour_distance_unit_kilometers: '{{distance}} KM',
  msg_tour_duration_in_hours_and_minutes: '{{hours}} H. {{minutes}} MIN.',
  msg_tour_duration_in_minutes: '{{minutes}} min.',
  msg_home_link_text: 'Home',
  msg_play_sample_audios: 'Play Tour Sample',
  msg_tour_overview_no_reviews: 'No reviews yet',
  msg_tour_overview_sites_title: 'List of sites',
  msg_error_something_went_wrong: 'Something went wrong. Please try again later.',
  msg_tour_overview_anonymous_user: 'Anonymous',
  msg_header_for_local_hosts: 'For local hosts',
  msg_header_explore: 'Explore',
  msg_header_travel_talk: 'Travel talk',
  msg_header_about_us: 'About us',
  msg_header_eu_project: 'Eu project',
  msg_footer_copyright: '© {{month}} {{year}}. All content rights belong to UAB GlobeTrott Travel',
  msg_footer_privacy_policy: 'Privacy Policy',
  msg_footer_terms_and_conditions: 'Terms and Conditions',
  msg_tour_overview_free_label: 'Free',
  msg_tour_card_free_label: 'Free',
  msg_select_placeholder: 'Select',
  msg_select_empty: 'Empty',
  msg_tour_filter_form_all_languages_option: 'All languages',
  msg_tour_filter_modal_travel_type_bike: 'Bike',
  msg_tour_filter_modal_travel_type_public_transportation: 'Public transportation',
  msg_tour_filter_modal_travel_type_walk: 'Walk',
  msg_tour_filter_modal_travel_type_car: 'Car',
  msg_tour_overview_description_title: 'Overview:',
  msg_tour_overview_amount_of_reviews_multiple: '({{amount}} reviews)',
  msg_tour_overview_amount_of_reviews_singular: '({{amount}} review)',
  msg_tour_overview_ratings_title: 'Tour reviews & Ratings:',
  msg_tour_overview_ratings_load_more_button_label: 'Load more',
  msg_tour_overview_author_credits_singular: 'Credits: ',
  msg_tour_overview_author_credits_multiple: 'Tour credits',
  msg_tour_credits_modal_title: 'Credits',
  msg_city_card_coming_soon: 'Coming soon',
  msg_tour_audio_tour_label: '🎧 Audio tour',
  msg_tour_text_only_tour_label: '🗺️ Route',
  msg_audio_sample_section_title: 'Listen to sample',
  msg_tour_overview_download_app_title: 'To get the full experience, please download GlobeTrott Travel app',
  msg_seo_home_page_title: 'Self-guided tours with the free GlobeTrott Travel App',
  msg_seo_home_page_title_og: `Local's recommendations at your fingertips`,
  msg_seo_home_page_description_og:
    "Discover {{city_amount}}+ cities in Europe, UK & US. Self-guided itineraries, immersive audio stories & local's tips available with our free travel app!",
  msg_seo_home_page_description:
    'Discover {{city_amount}}+ cities in Europe, UK & US with our free travel app! Get expert tips, self-guided tours & uncover hidden gems. Download now & start exploring!',
  msg_seo_city_tours_title: '{{city}} walking tours with a free travel app',
  msg_seo_city_tours_title_og: '{{city}} walking tours by locals ',
  msg_seo_city_tours_description_og:
    'Choose a self-guided tour in {{city}} to discover the city’s hidden gems and hear fascinating audio stories from locals with the free GlobeTrott Travel app.',
  msg_seo_city_tours_description:
    "Discover {{city}} with self-guided itineraries! Listen to immersive audio stories from locals and discover citie's hidden gems with a free travel app.",
  msg_seo_tour_description_og:
    'Easy-to-follow route, immersive audio stories and helpful recommendations along the way guarantees that you will discover the most exciting side of {{city}}.',
  msg_seo_tour_description:
    'Easy-to-follow route, immersive audio stories and helpful recommendations along the way guarantees that you will discover the most exciting side of {{city}}',
  msg_home_header_title: 'Let the <strong>locals guide you</strong>',
  msg_home_header_description:
    'Self-guided tours are created by local experts, so you could easily discover the most interesting hidden gems of the city!',
  msg_home_header_app_stores_title: 'Download free mobile app now & start exploring!',
  msg_city_list_page_attractions_section_title: 'Attractions',
  msg_city_list_page_tour_section_title: 'Tours',
  msg_attraction_card_featured_label: 'Featured',
  msg_filter_form_attractions_is_picturesque_label: 'Picturesque',
  msg_filter_form_attractions_tags_label: 'Interests',
  msg_no_attractions_label: 'There are no attractions available at the moment.',
  msg_attractions_overview_description_title: 'About',
  msg_attractions_overview_description_more_info: 'For more info <website>visit website</website>',
  msg_attractions_overview_description_buy_tickets_label: 'Buy tickets',
  msg_attractions_overview_map_get_directions_label: 'Get directions',
  msg_attractions_overview_map_section_title: 'Location',
  msg_attractions_overview_download_information_title:
    'To get the full experience please download GlobeTrott Travel app',
  msg_attractions_overview_special_offer_title: 'Special offer',
  msg_attractions_overview_special_offer_get_button_label: 'Get',
  msg_attractions_overview_special_offer_modal_button_label: 'Ok',
  msg_attractions_overview_related_tours_to_attractions_title: 'Attraction in tours',
  msg_attractions_overview_header_featured_label: 'Featured',
  msg_seo_city_attractions_title: 'Locals recommend: things to do and see in {{city}}',
  msg_seo_city_attractions_description:
    "Make the most of your trip to {{city}} with locals' tips and special discounts. Discover best restaurants and things to do with free GlobeTrott Travel app.",
  msg_seo_city_attractions_title_og: 'Locals recommend: things to do and see in {{city}}',
  msg_seo_city_attractions_description_og:
    "Make the most of your trip to {{city}} with locals' tips and special discounts. Discover best restaurants and things to do with free GlobeTrott Travel app.",
  msg_seo_attraction_title: '{{attraction_title}} | {{city_title}} | All you need to know',
  msg_seo_og_attraction_title: '{{attraction_title}} | {{city_title}} | All you need to know',
  msg_attractions_overview_special_offer_modal_tooltip_label: 'Copied!',
} as const;
