import Image from 'next/image';
import React from 'react';

import { styleUtils } from '@/config/style';
import AppStoreImg from '@/public/appStore.png';
import PlayStoryImg from '@/public/playStore.png';

import Link from '../Link/Link';

const AppStoreLinks = () => {
  return (
    <div css={[styleUtils.displayFlex, styleUtils.alignCenter]}>
      <Link
        css={styleUtils.mr10}
        target="_blank"
        href="https://apps.apple.com/lt/app/globetrott-travel-audio-guide/id1553250015"
      >
        <Image src={AppStoreImg} width={118} height={38} alt="app-store" />
      </Link>
      <Link target="_blank" href="https://play.google.com/store/apps/details?id=com.globetrott">
        <Image src={PlayStoryImg} width={138} height={57} alt="play-store" />
      </Link>
    </div>
  );
};

export default AppStoreLinks;
