import { css } from '@emotion/react';
import Image from 'next/image';
import React, { useState } from 'react';

import Container from '@/components/Container/Container';
import Hamburger from '@/components/Hamburger/Hamburger';
import Link from '@/components/Link/Link';
import MobileNavigation from '@/components/MobileNavigation/MobileNavigation';
import { HEADER_HEIGHT } from '@/config/constants';
import { ColorPalette, fontSize, fontWeight, media, spacing } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import HeaderLogoImg from '@/public/headerLogo.jpg';

const styles = {
  wrapper: css`
    background-color: ${ColorPalette.white};
    width: 100%;
  `,
  header: css`
    height: ${HEADER_HEIGHT}px;
    justify-content: space-between;
    display: flex;
    align-items: center;
  `,
  link: css`
    margin: 0 ${spacing['10']};
    color: ${ColorPalette.darkGray};
    font-size: ${fontSize['14']};
    font-weight: ${fontWeight.light};
  `,
  linkWrapper: css`
    display: flex;

    ${media.smallTablet} {
      display: none;
    }
  `,
  logo: css`
    ${media.smallTablet} {
      width: 200px;
      height: 50px;
    }
  `,
};

export const headerLinks = [
  { link: '/', label: 'msg_header_explore' },
  { link: 'https://www.globetrott.com/how-does-it-work', label: 'msg_header_for_local_hosts' },
  { link: 'https://www.globetrott.com/traveltalk', label: 'msg_header_travel_talk' },
  { link: 'https://www.globetrott.com/about-us', label: 'msg_header_about_us' },
  { link: 'https://www.globetrott.com/eu-eksperimentas', label: 'msg_header_eu_project' },
] as const;

const Header = () => {
  const { t } = useTranslation();
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  return (
    <div css={styles.wrapper}>
      <Container>
        <header css={styles.header}>
          <div>
            <Link href="/">
              <Image src={HeaderLogoImg} width={291} height={71} alt="logo" css={styles.logo} />
            </Link>
          </div>
          <Hamburger
            isOpen={isHamburgerOpen}
            onClick={() => {
              setIsHamburgerOpen(!isHamburgerOpen);
            }}
          />
          <div css={styles.linkWrapper}>
            {headerLinks.map((headerLink, index) => (
              <Link href={headerLink.link} key={index} css={styles.link}>
                {t(headerLink.label).toUpperCase()}
              </Link>
            ))}
          </div>
        </header>
      </Container>
      {isHamburgerOpen && <MobileNavigation onLinkPress={() => setIsHamburgerOpen(false)} />}
    </div>
  );
};

export default Header;
