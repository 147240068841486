import { css } from '@emotion/react';
import { format } from 'date-fns';
import Image from 'next/image';
import React from 'react';

import FacebookIcon from '@/assets/icons/facebook.svg';
import InstagramIcon from '@/assets/icons/instagram.svg';
import PinterestIcon from '@/assets/icons/pinterest.svg';
import Container from '@/components/Container/Container';
import Link from '@/components/Link/Link';
import { ColorPalette, fontSize, fontWeight, media, spacing, styleUtils } from '@/config/style';
import { useTranslation } from '@/hooks/useTranslation';
import LogoWhiteImg from '@/public/logoWhite.png';
import LtuFutureImg from '@/public/ltuFuture.png';

import AppStoreLinks from '../AppStoreLinks/AppStoreLinks';

const styles = {
  footer: css`
    background-color: ${ColorPalette.primary};
    height: 300px;
    margin-top: ${spacing['40']};

    ${media.mobile} {
      height: auto;
    }
  `,
  contentWrapper: css`
    align-items: center;
  `,
  upperContentWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: ${ColorPalette.gray};
    border-bottom-width: 1px;
    border-bottom-style: solid;

    ${media.mobile} {
      display: grid;
      justify-content: center;
      justify-items: center;
      padding-top: ${spacing['24']};
    }
  `,
  lowerContentWrapper: css`
    margin: ${spacing['40']} 0;
  `,
  socialsAndStoresWrapper: css`
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${media.mobile} {
      display: grid;
      justify-content: center;
    }
  `,
  link: css`
    margin-right: ${spacing['12']};
    padding: ${spacing['12']};
    border-radius: 50%;
    background-color: ${ColorPalette.white};
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  appRightsWrapper: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing['24']};

    ${media.mobile} {
      display: grid;
      justify-content: center;
      justify-items: center;
    }
  `,
  appRightsText: css`
    color: ${ColorPalette.lightGray};
    margin-right: ${spacing['10']};
    font-size: ${fontSize[14]};

    ${media.mobile} {
      margin: ${spacing['12']};
    }
  `,
  copyRightText: css`
    font-size: ${fontSize[14]};
    color: ${ColorPalette.smoke};
    font-weight: ${fontWeight.light};

    ${media.mobile} {
      margin-bottom: ${spacing['12']};
    }
  `,
};

const socials = [
  {
    icon: <InstagramIcon />,
    href: 'https://www.instagram.com/globetrott.travel',
  },
  {
    icon: <PinterestIcon />,
    href: 'https://www.pinterest.co.uk/globetrotttravel',
  },
  {
    icon: <FacebookIcon />,
    href: 'https://www.facebook.com/globetrott.travel',
  },
];

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer css={styles.footer}>
      <Container>
        <div css={styles.contentWrapper}>
          <div css={styles.upperContentWrapper}>
            <Link href="https://globetrott.com/ ">
              <Image src={LogoWhiteImg} width={268} height={48} alt="logo-white" />
            </Link>
            <Link href="https://www.globetrott.com/eu-eksperimentas">
              <Image src={LtuFutureImg} width={212} height={120} alt="lithuania-future" />
            </Link>
          </div>
          <div css={styles.lowerContentWrapper}>
            <div css={styles.socialsAndStoresWrapper}>
              <div css={[styleUtils.displayFlex, styleUtils.alignCenter]}>
                {socials.map((social, index) => (
                  <Link href={social.href} css={styles.link} key={index}>
                    {social.icon}
                  </Link>
                ))}
              </div>
              <AppStoreLinks />
            </div>
            <div css={styles.appRightsWrapper}>
              <div css={styles.copyRightText}>
                {t('msg_footer_copyright', {
                  month: format(new Date(), 'MMMM'),
                  year: format(new Date(), 'yyy'),
                })}
              </div>
              <div css={styleUtils.displayFlex}>
                <Link css={styles.appRightsText} href="https://www.globetrott.com/privacy-policy">
                  {t('msg_footer_privacy_policy')}
                </Link>
                <Link css={styles.appRightsText} href="https://www.globetrott.com/terms-and-conditions">
                  {t('msg_footer_terms_and_conditions')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
